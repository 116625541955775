/**
 * Browser check especially for IE11, heavily inspired by the NPM module "outdatedbrowser" (https://www.npmjs.com/package/outdatedbrowser)
 *
 * This must stay "old-fashioned" JS, since we cannot rely on modern JS being supported by the outdated browsers we
 * target here (IE 10 e.g. does not support "const")
 */
(function () {
    var supports = (function () {
        var div = document.createElement('div');
        var vendors = 'Khtml Ms O Moz Webkit'.split(' ');
        var len = vendors.length;

        return function (propertyName) {
            if (propertyName in div.style) return true;

            propertyName = propertyName.replace(/^[a-z]/, function (val) {
                return val.toUpperCase();
            });

            while (len--) {
                if (vendors[len] + propertyName in div.style) {
                    return true;
                }
            }
            return false;
        };
    })();

    var alertElement = document.querySelector('#outdated-browser');

    if (!alertElement) {
        return;
    }

    var isIE11 = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style;

    // borderImage is not supported in IE < 11; IE 11 needs a separate check
    // see http://browserhacks.com/#hack-acea075d0ac6954f275a70023906050c for the IE11 check
    if (!supports('borderImage') || isIE11) {
        alertElement.style['display'] = 'block';
    }
})();
